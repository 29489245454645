import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { AuthContext } from "src/context/AuthContext";
import userNavItems from "src/data/navItems/userNavItems";
import NavItem from "./NavItem";

const NavBar = () => {
  const authContext = React.useContext(AuthContext);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <Box display="flex" justifyContent="center">
          <Avatar sx={{ width: 64, height: 64 }} />
        </Box>

        <Box display="flex" justifyContent="center">
          <Typography variant="body1" color="textPrimary" textAlign="center">
            {authContext?.userData?.username}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {userNavItems.map((navItemsGroup, index) => (
        <List
          key={index}
          subheader={<ListSubheader>{navItemsGroup.subheader}</ListSubheader>}
        >
          {navItemsGroup.items.map((navItem, index) => (
            <NavItem key={index} {...navItem} />
          ))}
        </List>
      ))}
    </Box>
  );
  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        // display: { xs: "none", md: "block" },
        ["& .MuiDrawer-paper"]: {
          width: 256,
          top: 54,
          boxSizing: "border-box",
        },
      }}
    >
      {content}
    </Drawer>
  );
};

export default NavBar;
