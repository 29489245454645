import React from "react";
import { Box, CircularProgress } from "@mui/material";
import duckLogo from "src/assets/images/duckLogo.svg";

const containerStyles = (theme) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  left: 0,
  padding: theme.spacing(3),
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 2000,
});

const SlashScreen = () => {
  return (
    <Box sx={(theme) => containerStyles(theme)}>
      <Box display="flex" justifyContent="center" mb={6}>
        <img src={duckLogo} width="200px" />
      </Box>
      <CircularProgress size={30} />
    </Box>
  );
};

export default SlashScreen;
