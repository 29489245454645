import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import Account from "./Account";
// import MenuIcon from "@mui/icons-material/Menu";

const TopBar = () => {
  return (
    <AppBar sx={{ height: "54px" }}>
      <Toolbar style={{ height: "100%", minHeight: "auto" }}>
        {/* <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton onClick={() => {}}>
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "white" }} />
            </SvgIcon>
          </IconButton>
        </Box> */}
        <Box flexGrow={1} />
        <Account />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
