import React from "react";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import CustomGlobalStyles from "src/components/CustomGlobalStyles";
import customTheme from "src/theme";
import Routes from "./routes/Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthContextProvider from "./context/AuthContext";

let theme = createTheme(customTheme);
theme = responsiveFontSizes(theme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CustomGlobalStyles />
        <AuthContextProvider>
          <Routes />
        </AuthContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
