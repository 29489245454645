import InventoryIcon from "@mui/icons-material/Inventory";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AppsIcon from "@mui/icons-material/Apps";

export default [
  {
    subheader: "Menu",
    items: [
      {
        title: "Panel",
        icon: AppsIcon,
        path: "/panel",
      },
      {
        title: "Mi stock",
        icon: InventoryIcon,
        path: "/stock/sucursales/",
      },
      {
        title: "Totales",
        icon: MonetizationOnIcon,
        path: "/ventas-totales/sucursales/",
      },
    ],
  },
];
