import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AuthContext } from "src/context/AuthContext";
import { Typography } from "@mui/material";

const Account = () => {
  const authContext = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        component={Button}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Avatar sx={{ marginRight: 1, width: 24, height: 24 }} />
        <Typography variant="h6" style={{ color: "white" }}>
          {authContext?.userData?.username}
        </Typography>
      </Box>
      <Menu
        sx={{ width: 200 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            width: "200px",
          },
        }}
      >
        <MenuItem dense disableRipple onClick={authContext?.handleLogout}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Account;
