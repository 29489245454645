import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import SlashScreen from "src/components/SplashScreen";

const containerStyles = {
  minHeight: "100vh",
  display: "flex",
  "@media all and (-ms-high-contrast:none)": {
    height: 0, // IE11 fix
  },
};

const contentStyles = {
  flexGrow: 1,
  maxWidth: "100%",
  overflowX: "hidden",
};

const Auth = () => {
  return (
    <Box sx={containerStyles}>
      <Box sx={contentStyles}>
        <React.Suspense fallback={<SlashScreen />}>
          <Outlet />
        </React.Suspense>
      </Box>
    </Box>
  );
};

export default Auth;
