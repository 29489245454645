import axios from "axios";
import React from "react";
import { AuthContext } from "src/context/AuthContext";

export const PrivateInterceptor = ({ children }) => {
  const authContext = React.useContext(AuthContext);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        authContext.handleLogout();
      }
    }
  );
  return <React.Fragment>{children}</React.Fragment>;
};
