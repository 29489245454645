import React from "react";
import { GlobalStyles } from "@mui/material";

const CustomGlobalStyles = () => {
  return (
    <GlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
      }}
    />
  );
};

export default CustomGlobalStyles;
