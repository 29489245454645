import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import NavBar from "./NavBar";

const rootContainerStyle = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  width: "100%",
});

const containerStyle = {
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "54px",
};

const contentWrapperStyle = {
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingLeft: { xs: 0, md: 32 },
  paddingLeft: 32,
};

const contentStyle = {
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
};

const Dashboard = () => {
  return (
    <Box sx={(theme) => rootContainerStyle(theme)}>
      <NavBar />
      <TopBar />
      <Box sx={containerStyle}>
        <Box sx={contentWrapperStyle}>
          <Box sx={contentStyle}>
            <React.Suspense fallback={<LinearProgress />}>
              <Outlet />
            </React.Suspense>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
