import React from "react";
import { useRoutes } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { PrivateInterceptor } from "src/interceptos/PrivateInterceptor";
import privateRoutes from "./privateRoutes";
import publicRoutes from "./publicRoutes";

const Routes = () => {
  const authContext = React.useContext(AuthContext);

  if (authContext.isAuthenticated) {
    const routes = (
      <PrivateInterceptor>{useRoutes(privateRoutes)}</PrivateInterceptor>
    );
    return routes;
  }
  const routes = useRoutes(publicRoutes);
  return routes;
};

export default Routes;
