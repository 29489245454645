import axios from "axios";
import { useQuery } from "react-query";
import { fromApiUserData } from "src/adapters/userAdapters";
import { tokenHeader } from "./utils";

const userSerivices = {
  getUserData: async () => {
    const response = await axios.get("/api/users/user-data/", {
      headers: tokenHeader(),
    });
    return { ...response, data: fromApiUserData(response.data) };
  },
};

export const useGetUserData = (queryProps) => {
  return useQuery("getUserData", userSerivices.getUserData, {
    ...queryProps,
  });
};
