import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "src/layouts/Dashboard";

const MainView = React.lazy(() => import("src/views/MainView"));
const StockGeneral = React.lazy(() => import("src/views/StockGeneral"));
const MontosTotales = React.lazy(() => import("src/views/MontosTotales"));

export default [
  {
    path: "/",
    element: <Dashboard />,
    children: [
      { path: "/", element: <Navigate to={"/panel"} replace /> },
      {
        path: "panel",
        element: <MainView />,
      },
      {
        path: "stock/sucursales/",
        element: <StockGeneral />,
      },
      {
        path: "ventas-totales/sucursales/",
        element: <MontosTotales />,
      },
    ],
  },
  { path: "*", element: <Navigate to={"/panel"} replace /> },
];
