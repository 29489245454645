import React from "react";
import SlashScreen from "src/components/SplashScreen";
import { useGetUserData } from "src/services/userServices";
import {
  deleteLocalStorage,
  getAccessTokenLocalStorage,
  setAccessTokenLocalStorage,
  setUserDataLocalStorage,
} from "src/utils/localStorageApi";

export const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = React.useState(
    !!getAccessTokenLocalStorage()
  );
  const [userData, setUserData] = React.useState(null);
  const getUserDataQuery = useGetUserData({
    enabled: false,
    retry: false,
    onSuccess: (response) => setUserLoggedData(response.data),
  });

  const hasLoggedUser = () => {
    const hasAccessToken = !!getAccessTokenLocalStorage();
    if (hasAccessToken) {
      getUserDataQuery.refetch();
      setAuthenticated(true);
      return;
    }
    deleteLocalStorage();
  };

  const setLoggedUser = (responseData) => {
    const { token, userData } = responseData;
    setUserLoggedData(userData);
    setAccessTokenLocalStorage(token);
    setAuthenticated(true);
  };

  const setUserLoggedData = (userData) => {
    setUserData(userData);
    setUserDataLocalStorage(userData);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    setUserData(null);
    deleteLocalStorage();
  };

  React.useEffect(() => {
    hasLoggedUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userData, setLoggedUser, handleLogout }}
    >
      {getUserDataQuery.isFetching ? <SlashScreen /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
