import React from "react";
import { Button, ListItem } from "@mui/material";
import { matchPath, Link, useLocation } from "react-router-dom";

const buttonStyles = (theme) => ({
  color: theme.palette.text.secondary,
  padding: "10px 12px",
  justifyContent: "flex-start",
  textTransform: "none",
  letterSpacing: 0,
  width: "100%",
});

const iconStyles = (theme) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(1),
  fontSize: 20,
});

const titleStyles = {
  marginRight: "auto",
};

const activeButtonStyles = (theme) => ({
  color: theme.palette.secondary.main,
  "& $title": {
    fontWeight: theme.typography.fontWeightMedium,
  },
  "& $icon": {
    color: theme.palette.secondary.main,
  },
});

const NavItem = ({ title, path, icon: Icon }) => {
  const { pathname } = useLocation();
  const isMatch = !!matchPath(
    {
      path,
      end: true,
    },
    pathname
  );

  const btnStyles = (theme) =>
    isMatch
      ? { ...buttonStyles(theme), ...activeButtonStyles(theme) }
      : buttonStyles(theme);

  return (
    <ListItem sx={{ padding: "0px 6px 0px 6px" }}>
      <Button sx={(theme) => btnStyles(theme)} component={Link} to={path}>
        {Icon && <Icon sx={iconStyles} />}
        <span className={titleStyles}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
