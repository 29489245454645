import React from "react";
import { Navigate } from "react-router-dom";
import Auth from "src/layouts/Auth";

const Login = React.lazy(() => import("src/views/Login"));

export default [
  {
    path: "/",
    element: <Auth />,
    children: [
      { path: "/", element: <Navigate to={"/login"} replace /> },
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
  { path: "*", element: <Navigate to={"/login"} replace /> },
];
